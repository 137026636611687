<template>
  <el-dialog
    :title="!dataForm.id ? '新增' : !disabled ? '修改' : '查看'"
    :close-on-click-modal="false"
    :visible.sync="visible"
    append-to-body
    class="missionPart"
  >
    <el-form
      ref="dataForm"
      :model="dataForm"
      label-width="80px"
      @keyup.enter.native="dataFormSubmit()"
    >
      <div class="tablePart">
        <el-form-item label="公司" prop="companyId">
          <el-select
            v-model="dataForm.companyId"
            :disabled="disabled"
            filterable
          >
            <el-option
              v-for="(item, index) in companyList"
              :key="index"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="冷柜名字" prop="freezerId">
          <el-select
            v-model="dataForm.freezerId"
            :disabled="disabled"
            clearable
            filterable
          >
            <el-option
              v-for="(item, index) in freezerList"
              :key="index"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>

        <el-form-item label="星期" prop="weeks" class="fullList">
          <el-checkbox-group v-model="dataForm.weeks" size="medium">
            <el-checkbox-button
              v-for="(item, index) in weeksList"
              :key="index"
              :label="item.id"
              >{{ item.week }}</el-checkbox-button
            >
          </el-checkbox-group>
        </el-form-item>

        <el-form-item label="时段选择" prop="periodId">
          <el-select
            v-model="dataForm.periodId"
            :disabled="disabled"
            filterable
            clearable
          >
            <el-option
              v-for="(item, index) in periodList"
              :key="index"
              :label="item.withTimeName"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="存放数量" prop="count">
          <el-input-number
            v-model="dataForm.count"
            :disabled="disabled"
            :min="1"
          />
        </el-form-item>
      </div>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取消</el-button>
      <el-button type="primary" @click="dataFormSubmit()" v-if="!disabled"
        >确定</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import { dialogMixin, normal } from '@/mixins';

export default {
  mixins: [dialogMixin, normal],
  data() {
    return {
      visiblePopover: false,
      disabled: false,
      dataForm: {
        id: '',
        companyId: '',
        freezerId: '',
        periodId: '',
        count: 1,
        weeks: [1, 2, 3, 4, 5, 6, 7],
      },
      companyList: [],
      freezerList: [],
      periodList: [],
      weeksList: [
        { id: 1, week: '一' },
        { id: 2, week: '二' },
        { id: 3, week: '三' },
        { id: 4, week: '四' },
        { id: 5, week: '五' },
        { id: 6, week: '六' },
        { id: 7, week: '日' },
      ],
    };
  },
  created() {
    this.getCompany();
    this.getFreezerList();
    this.getPeriodList();
  },
  methods: {
    init(id, disabled) {
      this.dataForm.id = id || '';
      this.disabled = disabled;
      this.visible = true;
      this.$nextTick(() => {
        this.$refs['dataForm'].resetFields();
        if (this.dataForm.id) {
          this.$http({
            url: `/tc/companyFreezer/info/${this.dataForm.id}`,
            method: 'get',
          }).then(({ data }) => {
            if (data && data.code === 0) {
              this.dataForm = { ...data.companyfreezer };
              this.dataForm.weeks = data.companyfreezer.weeks
                ? data.companyfreezer.weeks
                    .split(',')
                    .map((item) => parseInt(item))
                : [];
            }
          });
        }
      });
    },
    // 表单提交
    dataFormSubmit() {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          this.$http({
            url: `/tc/companyFreezer/${!this.dataForm.id ? 'save' : 'update'}`,
            method: 'post',
            data: {
              ...this.dataForm,
              weeks: this.dataForm.weeks.map((i) => i).join(','),
            },
          }).then(({ data }) => {
            if (data && data.status === 0) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1500,
              });
              this.visible = false;
              this.$emit('success');
            } else if (data && data.status === 422) {
              this.$message({
                message: data.errors.periodId,
                type: 'error',
                duration: 1500,
              });
            }
          });
        }
      });
    },
    getCompany() {
      this.$api({
        url: '/tc/company/combo',
        after: (data) => {
          if (data && data.code === 0) {
            this.companyList = data.list;
          } else {
            this.companyList = [];
          }
        },
      });
    },
    getFreezerList() {
      this.$http({
        url: `/cc/freezer/all`,
        method: 'get',
        params: { isUse: 1 },
      }).then(({ data }) => {
        if (data && data.status === 0) {
          this.freezerList = data.data.items;
        } else {
          this.freezerList = [];
        }
      });
    },
    getPeriodList() {
      this.$http({
        url: `/cc/global-period/all`,
        method: 'get',
        params: { isUse: 1 },
      }).then(({ data }) => {
        if (data && data.status === 0) {
          this.periodList = data.data.items;
        } else {
          this.periodList = [];
        }
      });
    },
  },
};
</script>
<style scoped>
.mod-org {
  .org-list__input,
  .icon-list__input {
    > .el-input__inner {
      cursor: pointer;
    }
  }
  & __icon-popover {
    max-width: 350px;
  }

  & __icon-list {
    max-height: 380px;
    padding: 0;
    margin: -8px 0 0 -8px;

    > .el-button {
      padding: 8px;
      margin: 8px 0 0 8px;

      > span {
        display: inline-block;
        vertical-align: middle;
        width: 18px;
        height: 18px;
        font-size: 18px;
      }
    }
  }
  .icon-list__tips {
    font-size: 18px;
    text-align: center;
    color: #e6a23c;
    cursor: pointer;
  }
}
</style>
