<template>
  <div class="warehouse-page">
    <el-form inline class="bysearchForm" ref="dataForm">
      <div class="searchLine">
        <el-form-item class="typeInput">
          <el-button
            v-if="isAuth('tc:companyFreezer:save')"
            type="primary"
            @click="$dialog('addOrUpdate')"
            >新增公司团餐冷柜</el-button
          >
          <el-button
            v-if="isAuth('tc:companyFreezer:delete')"
            type="danger"
            @click="deleteHandle()"
            :disabled="detailListSelections.length <= 0"
            >批量删除公司团餐冷柜</el-button
          >
          <el-button
            v-if="isAuth('tc:companyFreezer:update')"
            type="info"
            :disabled="!(detailListSelections.length === 1)"
            @click="$dialog('addOrUpdate', detailListSelections[0].id)"
            >修改冷柜信息</el-button
          >
          <el-button
            v-if="isAuth('tc:companyFreezer:info')"
            type="success"
            :disabled="!(detailListSelections.length === 1)"
            @click="$dialog('addOrUpdate', detailListSelections[0].id, true)"
            >查看冷柜信息</el-button
          >
        </el-form-item>
      </div>
    </el-form>
    <div class="main-container">
      <div class="aside-wrap">
        <h5>公司列表</h5>
        <el-table
          height="calc(100vh - 210px)"
          :data="dataList"
          ref="dataList"
          border
          :row-class-name="tableRowClassName"
          @selection-change="$selectionChange($event, 'dataList')"
          @row-click="$clickRow($event, 'dataList', getTaskDataList($event))"
          @select="$select(arguments, 'dataList')"
          @select-all="$clearSelection('dataList')"
        >
          <el-table-column
            type="selection"
            header-align="center"
            align="center"
            width="50"
          />
          <el-table-column
            prop="name"
            header-align="center"
            align="center"
            label="公司列表"
          />
        </el-table>
        <el-pagination
          background
          @size-change="$sizeChange($event, 'dataList')"
          @current-change="$currentChange($event, 'dataList')"
          :current-page="dataListIndex"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="dataListSize"
          :total="dataListCount"
          layout="total, sizes, prev, pager, next"
        />
      </div>
      <div class="main-wrap">
        <h5>公司团餐冷柜</h5>
        <el-table
          :data="detailList"
          ref="detailList"
          border
          stripe
          v-loading="detailListLoading"
          @row-click="$clickRow($event, 'detailList')"
          @selection-change="$selectionChange($event, 'detailList')"
          style="width: 100%"
        >
          <el-table-column
            type="selection"
            prop="id"
            header-align="center"
            align="center"
            width="50"
          />
          <el-table-column
            prop="freezerNameAndSn"
            header-align="center"
            align="center"
            label="冷柜"
          />
          <el-table-column
            prop="periodWithTimeName"
            header-align="center"
            align="center"
            label="时段"
          />
          <el-table-column
            prop="weeks"
            header-align="center"
            align="center"
            label="星期"
          />
          <el-table-column
            prop="count"
            header-align="center"
            align="center"
            label="团餐份数"
          />
        </el-table>
        <el-pagination
          background
          @size-change="$sizeChange($event, 'detailList')"
          @current-change="$currentChange($event, 'detailList')"
          :current-page="detailListIndex"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="detailListSize"
          :total="detailListCount"
          layout="total, sizes, prev, pager, next, jumper"
        />
      </div>
    </div>
    <!-- 弹窗, 新增 / 修改 -->
    <add-or-update
      v-if="addOrUpdateVisible"
      ref="addOrUpdate"
      @success="getTaskDataList"
    />
  </div>
</template>

<script>
import { threeListMixin, normal } from '@/mixins';
import AddOrUpdate from './company-and-freezer-add-or-update';
export default {
  mixins: [normal, threeListMixin],
  data() {
    return {
      companyIdsAndName: [],
      companyId: '',
      name: [],
      taskDataList: [],
      addOrUpdateVisible: false,
    };
  },
  //注册需要引入的组件，即一个vue页面，这里指employees-add-or-update页面，
  components: {
    //要注册的文件首字母大写
    AddOrUpdate,
  },
  activated() {
    this.getDataList();
  },
  methods: {
    tableRowClassName({ row }) {
      // this.
      if (row.isUse === 0) return 'danger-row';
    },
    // 获取数据列表
    getDataList() {
      this.$api({
        url: '/tc/company/query',
        params: {
          page: this.dataListIndex,
          limit: this.dataListSize,
        },
        after: (data) => {
          if (data && data.code === 0) {
            this.dataList = data.list.records;
            this.dataListCount = data.list.total;
          } else {
            this.dataList = [];
            this.dataListSize = 0;
          }
        },
      });
    },
    // 获取数据列表
    getTaskDataList(row) {
      this.companyId = row ? row.id : this.companyId;
      this.$api({
        url: '/tc/companyFreezer/list',
        params: {
          page: this.detailListIndex,
          limit: this.detailListSize,
          companyId: this.companyId,
        },
        after: (data) => {
          if (data && data.code === 0) {
            if (data.page && data.page.records?.length) {
              this.detailList = data.page.records;
              this.detailListCount = data.page.total;
            } else {
              this.detailList = [];
              this.detailListCount = 0;
            }
          } else {
            this.detailList = [];
            this.detailListCount = 0;
          }
        },
      });
    },
    // 删除
    deleteHandle(id) {
      let ids = id
        ? [id]
        : this.detailListSelections.map((item) => {
            return item.id;
          });
      console.log(ids);
      this.$handleDelete({
        tip: `是否确认对这些冷柜绑定进行删除操作?`,
        url: '/tc/companyFreezer/delete',
        data: ids,
        after: () => {
          this.getTaskDataList();
        },
      });
    },
  },
};
</script>

<style>
.bg-purple-dark {
  background: #99a9bf;
}
.bg-purple {
  background: #d3dce6;
}
.bg-purple-light {
  background: #e5e9f2;
}
.grid-content {
  border-radius: 4px;
  min-height: 36px;
}
.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}
</style>
